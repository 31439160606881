<template>
  <div>
    <div class="content_center">
      กำลังโหลด...
    </div>
    <RedirectErrorModal ref="RedirectErrorModal" @Accept="onRedirect" />
  </div>
</template>

<script>
const RedirectErrorModal = () => import("@/components/RedirectErrorModal");

const TITTLE = {
  "1": "โปรโมชัน",
  "2": "แลกคะแนนสะสม",
  "3": "คำนวณค่างวดออนไลน์"
};

export default {
  components: {
    RedirectErrorModal
  },
  data() {
    return {
      lineProfile: {},
      redirectID: this.$route.query.id,
      interval: null,

      // new state
      getOs: ""
    };
  },
  beforeCreate() {
    if (this.$route.name == "Promotion") {
      this.$liff
        .init({ liffId: this.$liff_ID_Promotion_Old })
        .then(() => {
          if (!this.$liff.isLoggedIn()) {
            const queryString = decodeURIComponent(
              window.location.href
            ).replace("?liff.state=", "");

            let url = new URL(queryString);

            const id = url.searchParams.get("id");
            this.$liff
              .login({
                redirectUri: this.$BASE_URL + "/promotion/?id=" + id
              })
              .then(() => {
                this.$liff.getProfile().then(profile => {
                  this.lineProfile = profile;
                  this.checkTerm(profile.userId);
                });
              });
          } else {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.$liff
        .init({ liffId: this.$liff_ID_Promotion })
        .then(() => {
          if (!this.$liff.isLoggedIn()) {
            // this.$cookies.set("redirectID", this.$route.query.id);

            const queryString = decodeURIComponent(
              window.location.href
            ).replace("?liff.state=", "");

            let url = new URL(queryString);

            const id = url.searchParams.get("id");
            this.$liff
              .login({
                redirectUri: this.$BASE_URL + "/redirect/?id=" + id
              })
              .then(() => {
                this.$liff.getProfile().then(profile => {
                  this.lineProfile = profile;
                  this.checkTerm(profile.userId);
                });
              });
          } else {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  created() {
    this.getOs = this.$liff.getOS();
  },
  mounted() {
    // this.interval = setInterval(() => {
    //   console.log(this.$route.name, this.redirectID);
    //   if (this.$route.name == "Promotion") {
    //     this.redirectID = "1";
    //     clearInterval(this.interval);
    //     this.redirectOld();
    //   } else {
    //     if (
    //       this.redirectID != undefined &&
    //       this.lineProfile.userId != undefined
    //     ) {
    //       clearInterval(this.interval);
    //       this.redirect();
    //     }
    //   }
    // }, 500);
    document.title = "กำลังโหลด";
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.replace({
                path: "/policy",
                query: {
                  lineid: lineId,
                  redirectID: this.redirectID,
                  isClose: true,
                  term: true
                }
              });
            } else {
              this.redirect();
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.replace({
              path: "/policy",
              query: {
                lineid: lineId,
                redirectID: this.redirectID,
                isClose: true,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // redirectOld() {
    //   var myHeaders = new Headers();
    //   myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
    //   var requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //     redirect: "follow"
    //   };

    //   fetch(`${this.$API_DEV_LINE_SKL}/redirect/promotion`, requestOptions)
    //     .then(response => response.json())
    //     .then(result => {
    //       this.onRedirect(result.detail.url);
    //     })
    //     .catch(error => console.error("error", error));
    // },
    redirect() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var raw = JSON.stringify({
        lineId: this.lineProfile.userId,
        TypeId: this.redirectID
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Redirect`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log("API redirect", result);
          if (result.result == 1) {
            if (result.detail.status) {
              if (!result.detail.url) {
                window.open(`${this.$PATH_SKL}`, "_self");
                // this.$liff.closeWindow();
                // window.location.href = this.$PATH_SKL;
              } else {
                this.onRedirect(result.detail.url);
              }
            } else {
              this.$refs.RedirectErrorModal.message =
                result.detail.errorMessage;
              this.$refs.RedirectErrorModal.des = result.detail.url;
              this.$bvModal.show("redirectErrorModal");
            }
          }
        })
        .catch(error => console.error("error", error));
    },
    onRedirect(des) {
      if (des.startsWith("http")) {
        // window.location.href = des;
        window.open(des, "_self");
        // if (this.getOs == "android") {
        //   setTimeout(() => {
        //     this.closeWindow();
        //   }, 500);
        // }
      } else {
        this.$liff
          .sendMessages([
            {
              type: "text",
              text: des
            }
          ])
          .then(() => {
            this.$liff.closeWindow();
          });
      }
    },
    closeWindow() {
      console.log("closeWindow is worked windows");
      try {
        window.close();
      } catch (error) {
        console.log(error);
      }

      console.log("closeWindow is worked leff");
      try {
        this.$liff.closeWindow();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.content_center {
  position: absolute;
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -50px; /* margin is -0.5 * dimension */
  margin-top: -25px;
}
</style>
